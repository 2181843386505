import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";

const Privacy = () => {
  return (
    <LayoutWrapper
      title="Privacy Policy | The Selby Team"
      desc="View our privacy policy here. Make The Selby Team your residential realtor today."
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-center text-[24px] font-bold mb-[20px]">Privacy Policy</h1>
            <p className="mb-10">
              Protecting your private information is our priority. This Statement of Privacy applies
              to https://selbysellssd.com/, and The Selby Team and governs data collection and
              usage. For the purposes of this Privacy Policy, unless otherwise noted, all references
              to The Selby Team include https://selbysellssd.com//. The The Selby Team website is a
              general informational site. By using the The Selby Team website, you consent to the
              data practices described in this statement.{" "}
            </p>
            <h2 className="mt-[24px] text-[20px] font-bold mb-[20px]">
              Collection of your Personal Information
            </h2>
            <p className="mb-10">
              In order to better provide you with products and services offered, The Selby Team may
              collect personally identifiable information, such as your:
              <ul className="list-disc ml-24 my-24">
                <li>First and Last Name </li>
                <li>E-mail Address </li>
                <li>Phone Number </li>
              </ul>
              We do not collect any personal information about you unless you voluntarily provide it
              to us. However, you may be required to provide certain personal information to us when
              you elect to use certain products or services. These may include: (a) registering for
              an account; (b) entering a sweepstakes or contest sponsored by us or one of our
              partners; (c) signing up for special offers from selected third parties; (d) sending
              us an email message; (e) submitting your credit card or other payment information when
              ordering and purchasing products and services. To wit, we will use your information
              for, but not limited to, communicating with you in relation to services and/or
              products you have requested from us. We also may gather additional personal or
              non-personal information in the future.
            </p>
            <h2 className="mt-[24px] text-[20px] font-bold mb-[20px]">
              Use of your Personal Information
            </h2>
            <p className="mb-10">
              The Selby Team collects and uses your personal information to operate and deliver the
              services you have requested.{" "}
            </p>

            <p className="mb-10">
              The Selby Team may also use your personally identifiable information to inform you of
              other products or services available from The Selby Team and its affiliates.
            </p>
            <h2 className="mt-[24px] text-[20px] font-bold mb-[20px]">
              Sharing Information with Third Parties
            </h2>
            <p className="mb-10">
              The Selby Team does not sell, rent or lease its customer lists to third parties.{" "}
            </p>
            <p className="mb-10">
              The Selby Team may share data with trusted partners to help perform statistical
              analysis, send you email or postal mail, provide customer support, or arrange for
              deliveries. All such third parties are prohibited from using your personal information
              except to provide these services to The Selby Team, and they are required to maintain
              the confidentiality of your information.{" "}
            </p>
            <p className="mb-10">
              The Selby Team may disclose your personal information, without notice, if required to
              do so by law or in the good faith belief that such action is necessary to: (a) conform
              to the edicts of the law or comply with legal process served on The Selby Team or the
              site; (b) protect and defend the rights or property of The Selby Team; and/or (c) act
              under exigent circumstances to protect the personal safety of users of The Selby Team,
              or the public.{" "}
            </p>
            <h2 className="mt-[24px] text-[20px] font-bold mb-[20px]">
              Automatically Collected Information
            </h2>
            <p className="mb-10">
              Information about your computer hardware and software may be automatically collected
              by The Selby Team. This information can include: your IP address, browser type, domain
              names, access times and referring website addresses. This information is used for the
              operation of the service, to maintain quality of the service, and to provide general
              statistics regarding use of the The Selby Team website.{" "}
            </p>
            <h2 className="mt-[24px] text-[20px] font-bold mb-[20px]">Use of Cookies</h2>
            <p className="mb-10">
              The The Selby Team website may use "cookies" to help you personalize your online
              experience. A cookie is a text file that is placed on your hard disk by a web page
              server. Cookies cannot be used to run programs or deliver viruses to your computer.
              Cookies are uniquely assigned to you, and can only be read by a web server in the
              domain that issued the cookie to you.{" "}
            </p>

            <p className="mb-10">
              One of the primary purposes of cookies is to provide a convenience feature to save you
              time. The purpose of a cookie is to tell the Web server that you have returned to a
              specific page. For example, if you personalize The Selby Team pages, or register with
              The Selby Team site or services, a cookie helps The Selby Team to recall your specific
              information on subsequent visits. This simplifies the process of recording your
              personal information, such as billing addresses, shipping addresses, and so on. When
              you return to the same The Selby Team website, the information you previously provided
              can be retrieved, so you can easily use the The Selby Team features that you
              customized.{" "}
            </p>

            <p className="mb-10">
              You have the ability to accept or decline cookies. Most Web browsers automatically
              accept cookies, but you can usually modify your browser setting to decline cookies if
              you prefer. If you choose to decline cookies, you may not be able to fully experience
              the interactive features of the The Selby Team services or websites you visit.
            </p>
            <h2 className="mt-[24px] text-[20px] font-bold mb-[20px]">Links</h2>
            <p className="mb-10">
              This website contains links to other sites. Please be aware that we are not
              responsible for the content or privacy practices of such other sites. We encourage our
              users to be aware when they leave our site and to read the privacy statements of any
              other site that collects personally identifiable information.{" "}
            </p>
            <h2 className="mt-[24px] text-[20px] font-bold mb-[20px]">Right to Deletion</h2>
            <p>
              Subject to certain exceptions set out below, on receipt of a verifiable request from
              you, we will:
              <ul className="list-disc ml-24 my-24">
                <li>Delete your personal information from our records; and </li>
                <li>
                  Direct any service providers to delete your personal information from their
                  records.
                </li>
              </ul>
              Please note that we may not be able to comply with requests to delete your personal
              information if it is necessary to:
              <ul className="list-disc ml-24 my-24">
                <li>
                  Complete the transaction for which the personal information was collected, fulfill
                  the terms of a written warranty or product recall conducted in accordance with
                  federal law, provide a good or service requested by you, or reasonably anticipated
                  within the context of our ongoing business relationship with you, or otherwise
                  perform a contract between you and us;{" "}
                </li>
                <li>
                  Detect security incidents, protect against malicious, deceptive, fraudulent, or
                  illegal activity; or prosecute those responsible for that activity;{" "}
                </li>
                <li>
                  Debug to identify and repair errors that impair existing intended functionality;
                  Exercise free speech, ensure the right of another consumer to exercise his or her
                  right of free speech, or exercise another right provided for by law;{" "}
                </li>
                <li>Comply with the California Electronic Communications Privacy Act; </li>
                <li>
                  Engage in public or peer-reviewed scientific, historical, or statistical research
                  in the public interest that adheres to all other applicable ethics and privacy
                  laws, when our deletion of the information is likely to render impossible or
                  seriously impair the achievement of such research, provided we have obtained your
                  informed consent;{" "}
                </li>
                <li>
                  Enable solely internal uses that are reasonably aligned with your expectations
                  based on your relationship with us;{" "}
                </li>
                <li>Comply with an existing legal obligation; or </li>
                <li>
                  Otherwise use your personal information, internally, in a lawful manner that is
                  compatible with the context in which you provided the information.{" "}
                </li>
              </ul>
            </p>
            <h2 className="mt-[24px] text-[20px] font-bold mb-[20px]">Children Under Thirteen</h2>
            <p className="mb-10">
              The Selby Team does not knowingly collect personally identifiable information from
              children under the age of thirteen. If you are under the age of thirteen, you must ask
              your parent or guardian for permission to use this website.{" "}
            </p>
            <h2 className="mt-[24px] text-[20px] font-bold mb-[20px]">E-mail Communications</h2>
            <p className="mb-10">
              From time to time, The Selby Team may contact you via email for the purpose of
              providing announcements, promotional offers, alerts, confirmations, surveys, and/or
              other general communication. In order to improve our Services, we may receive a
              notification when you open an email from The Selby Team or click on a link therein.
            </p>
            <p className="mb-10">
              If you would like to stop receiving marketing or promotional communications via email
              from The Selby Team, you may opt out of such communications by clicking on the
              UNSUBSCRIBE button.
            </p>
            <h2 className="mt-[24px] text-[20px] font-bold mb-[20px]">Changes to this Statement</h2>
            <p className="mb-10">
              The Selby Team reserves the right to change this Privacy Policy from time to time. We
              will notify you about significant changes in the way we treat personal information by
              sending a notice to the primary email address specified in your account, by placing a
              prominent notice on our website, and/or by updating any privacy information. Your
              continued use of the website and/or Services available after such modifications will
              constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement
              to abide and be bound by that Policy.{" "}
            </p>
            <h2 className="mt-[24px] text-[20px] font-bold mb-[20px]">Contact Information</h2>
            <p className="mb-10">
              The Selby Team welcomes your questions or comments regarding this Statement of
              Privacy. If you believe that The Selby Team has not adhered to this Statement, please
              contact The Selby Team at:
            </p>
            <p className="mb-10">
              <a href="mailto:info@selbysellssd.com">info@selbysellssd.com</a>
            </p>
            <p className="mb-10">Last updated: August 2023</p>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default Privacy;
